<template>
    <div v-loading="$wait.is('cars.loading')" class="card card-f-h flex flex-col" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal @success="getAll" />

        <ModalAddCar
            :visible="modalAddCar"
            @refresh="getAll"
            @close="modalAddCar = false"
        />

        <ModalEditCar
            :visible="modalEditCar"
            :selectedCarId="selectedCarId"
            @refresh="getAll"
            @close="modalEditCar = false"
        />

        <div class="flex items-center justify-between border-b border-gray-300 pb-4 mb-5">
            <el-dropdown trigger="click">
                <el-button type="primary">
                    {{ $t('cars.menu') }} <i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <router-link :to="{ name: 'cars_archived' }">
                        <el-dropdown-item>
                            {{ $t('cars.archived') }}
                        </el-dropdown-item>
                    </router-link>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <CarsAllTable
            :carsData="carsData"
            @handleEdit="showEditModal"
            @handleArchive="archiveCar"
        />

        <NoDataInformation :data="noDataInformation" waitKey="cars.loading" />
    </div>
</template>
<script>
export default {
    components: {
        CarsAllTable: () => import(/* webpackChunkName: "Cars/CarsAllTable" */ './components/CarsAllTable.vue'),
        ModalAddCar:  () => import(/* webpackChunkName: "Cars/ModalAddCar" */ './modals/ModalAddCar.vue'),
        ModalEditCar: () => import(/* webpackChunkName: "Cars/ModalEditCar" */ './modals/ModalEditCar.vue'),
    },

    data() {
        return {
            noDataInformation: false,
            carsData:          [],
            selectedCarId:     null,
            modalAddCar:       false,
            modalEditCar:      false,
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('assets.cars'));
        this.$store.commit('setActiveMenuItem', '12');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('cars.loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/assets/cars`);
            this.carsData = data;
            this.noDataInformation = data.length;
            this.$wait.end('cars.loading');
        },

        showEditModal(id) {
            this.selectedCarId = id;
            this.modalEditCar = true;
        },

        showAddModal() {
            this.modalAddCar = true;
        },

        archiveCar(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/assets/cars/${id}`,
            });
        },
    },
};
</script>
